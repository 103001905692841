import type { LinksFunction, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useRef } from 'react'
import { Toaster } from 'react-hot-toast'

import ErrorStateWithRoody from '~/components/ErrorStateWithRoody'
import NetworkIndicator from '~/components/NetworkIndicator'
import { useReadAdminContextMenuToggle } from '~/features/admin-context-menu/stores/admin-menu-store'
import { AppSidebarContext, initializeAppSidebarStore } from '~/hooks/useAppSidebar'
import useSetupSentryUser from '~/hooks/useSetupSentryUser'
import getBrowserEnv from '~/server/env.server'
import { queryClient } from '~/utils/query-client'

import '~/assets/fontawesome.css'
import '~/assets/main.css'

export let links: LinksFunction = () => {
  return [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
  ]
}

export const meta: MetaFunction = () => {
  return [{ title: 'Rooquest' }]
}

export const shouldRevalidate = () => false

export let loader = async () => {
  return { env: getBrowserEnv() }
}

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useLoaderData<typeof loader>()

  return (
    <html lang="en">
      <head suppressHydrationWarning>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-[#f9fafb] min-h-screen">
        <AppWithProviders>{children}</AppWithProviders>

        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{ __html: `window.env=${JSON.stringify(loaderData?.env)}` }}
        />
        {loaderData?.env.GTAG && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${loaderData?.env.GTAG}`}
          ></script>
        )}
        {loaderData?.env.GTAG && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${loaderData?.env.GTAG}');`,
            }}
          />
        )}

        {loaderData?.env.APP_ENV === 'prod' && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "nqw8ga6tim");`,
            }}
          />
        )}
      </body>
    </html>
  )
}

function App() {
  useSetupSentryUser()
  const isAdminMode = useReadAdminContextMenuToggle('Admin Mode')

  return (
    <>
      <AnimatePresence>
        {isAdminMode && (
          <motion.div
            animate={{ height: 'auto' }}
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            className="border-b-2 border-red-500"
          >
            <div className="bg-red-100 text-center text-sm text-red-500 font-medium p-2">
              Admin Mode Enabled
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Outlet />
      <NetworkIndicator />
      <Toaster position="bottom-right" reverseOrder={false} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}

function AppWithProviders({ children }: { children: ReactNode }) {
  const matches = useMatches()
  const routeIsOneWhereSidebarShouldBeClosed = useRef(
    !!matches.find((match) => (match.handle as any)?.appSidebarClosed)
  )
  const appSidebarStore = useRef(
    initializeAppSidebarStore(routeIsOneWhereSidebarShouldBeClosed.current)
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AppSidebarContext.Provider value={appSidebarStore.current}>
        {children}
      </AppSidebarContext.Provider>
    </QueryClientProvider>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return (
    <div className="h-screen grid place-items-center">
      <ErrorStateWithRoody
        title="Something went very wrong"
        body="Don't worry, we've been alerted. Please come back a little bit later."
      />
    </div>
  )
}
